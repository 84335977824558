/* eslint-disable default-case */
import React, { useEffect, useState, useContext } from "react"
import PropTypes from "prop-types"
import "../styles/Collectables.css"
import Collectable from "./Collectable"

import AppContext from "../utilities/AppContext"
import scale from "../utilities/scale"
import checkOverlap from "../utilities/checkOverlap"

const Collectables = ({
  isMobile,
  isActive,
  screenWidth,
  screenHeight,
  dengueMode,
  setDengueMode,
}) => {
  const { killPlayer, points, incrementPoints, } = useContext(AppContext)
  const [collectableDuration, setCollectableDuration] = useState(200)
  const [dengueProbability, setDengueProbability] = useState(2)
  const [dengueModeCounter, setDengueModeCounter] = useState(0)
  const [playerBoundingBox, setPlayerBoundingBox] = useState()

  const pointsVelocityCap = 50

  // Game difficulty scale & dengue mode
  useEffect(() => {
    const dengueModeCounterValue = dengueModeCounter
    if (dengueModeCounterValue === 10) {
      setDengueModeCounter(0)
      setDengueMode(true)
    } else {
      setDengueModeCounter(dengueModeCounterValue + 1)
    }
    
    if (points <= pointsVelocityCap) {
      const duration = scale(points, 0, pointsVelocityCap, 300, 100)
      setCollectableDuration(duration)
    }
  }, [points])

  useEffect(() => {
    let counter
    if (dengueMode) {
      const duration = collectableDuration
      setCollectableDuration(duration / 1.25)
      counter = setTimeout(() => {
        setCollectableDuration(duration)
        setDengueMode(false)
      }, 10000)
    }
    return () => {
      counter && clearTimeout(counter)
    }
  }, [dengueMode])

  // Trigger next collectable if current is positioned past 1/3 of screen
  const [currentlyTriggered, setCurrentlyTriggered] = useState(["0"])
  function triggerNext(key, remove = false) {
    const arr =  [...currentlyTriggered]
    if (!remove) {
      arr.push(key)
      setCurrentlyTriggered(arr)
    } else {
      const index = arr.indexOf(key)
      arr.splice(index, 1)
      setCurrentlyTriggered(arr)
    }
  }

  useEffect(() => {
    setCurrentlyTriggered(["0"])
    setDengueModeCounter(0)
  }, [isActive])



  // Get player position
  function handlePlayerPositionEvent(event) {
    setPlayerBoundingBox(event.detail.boundingBox)
  }

  useEffect(() => {
    window.addEventListener("playerPositionEvent", handlePlayerPositionEvent)
    return () => window.removeEventListener("playerPositionEvent", handlePlayerPositionEvent)
  }, [])

  const [collectableCollided0, setCollectableCollided0] = useState(false)
  const [collectableCollided1, setCollectableCollided1] = useState(false)
  const [collectableCollided2, setCollectableCollided2] = useState(false)

  async function getBoundingBox(id, box, type, isGolden) {
    const isOverlaping = checkOverlap(playerBoundingBox, box)
    isOverlaping.then(value => {
      if (
        (id === "0" && !collectableCollided0)
        || (id === "1" && !collectableCollided1)
        || (id === "2" && !collectableCollided2)
      ) {
        if (value) {
          if (type === 0) {
            killPlayer()
          } else {
            isGolden
            ? incrementPoints(3, true)
            : incrementPoints(1)
          }

          if (id === "0") setCollectableCollided0(true)
          if (id === "1") setCollectableCollided1(true)
          if (id === "2") setCollectableCollided2(true)
        }
      }
    })
  }

  function resetCallback(id) {
    switch (id) {
      case "0": setCollectableCollided0(false)
      break
      case "1": setCollectableCollided1(false)
      break
      case "2": setCollectableCollided2(false)
    }
  }

  return (
    <div className="collectables-container">
      {isActive && (
        <>
          <Collectable
            keyName="0"
            killPlayer= {killPlayer}
            boundingBoxCallback={getBoundingBox}
            incrementPoints= {incrementPoints}
            dengueProbability={dengueProbability}
            collectableDuration={collectableDuration}
            dengueMode={dengueMode}
            triggerNext={triggerNext}
            currentlyTriggered={currentlyTriggered}
            hasCollided={collectableCollided0}
            resetCallback={resetCallback}
          />
          <Collectable
            keyName="1"
            killPlayer= {killPlayer}
            boundingBoxCallback={getBoundingBox}
            incrementPoints= {incrementPoints}
            dengueProbability={dengueProbability}
            collectableDuration={collectableDuration}
            dengueMode={dengueMode}
            triggerNext={triggerNext}
            currentlyTriggered={currentlyTriggered}
            hasCollided={collectableCollided1}
            resetCallback={resetCallback}
          />
          <Collectable
            keyName="2"
            killPlayer= {killPlayer}
            boundingBoxCallback={getBoundingBox}
            incrementPoints= {incrementPoints}
            dengueProbability={dengueProbability}
            collectableDuration={collectableDuration}
            dengueMode={dengueMode}
            triggerNext={triggerNext}
            currentlyTriggered={currentlyTriggered}
            hasCollided={collectableCollided2}
            resetCallback={resetCallback}
            last={true}
          />
        </>
      )}
    </div>
  )
}

export default Collectables

Collectables.propTypes = {
  isMobile: PropTypes.bool,
  isActive: PropTypes.bool.isRequired,
  dengueMode: PropTypes.bool.isRequired,
  setDengueMode: PropTypes.func.isRequired,
  screenWidth: PropTypes.number,
  screenHeight: PropTypes.number,
}
