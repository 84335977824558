import React from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import "../styles/Overlay.css"
import controlsDesktopSprite from "../static/images/ui/controlsDesktopSprite.png"
import controlsMobileSprite from "../static/images/ui/controlsMobileSprite.png"
import startDesktop from "../static/images/ui/startDesktop.png"
import startMobile from "../static/images/ui/startMobile.png"
import retrySprite from "../static/images/ui/retrySprite.png"
import shareCtaSprite from "../static/images/ui/shareCtaSprite.png"
import cursorHoverImage from "../static/images/ui/cursorHover.png"

const Overlay = ({
  type,
  isMobile,
  callback,
  hasScrim,
  hasBlackScrim,
  imageHref,
}) => {
  const initialOverlay = () => {
    if (isMobile) return (
      <div
        className="overlayInitialWrapper"
        onClick={callback}
      >
        <img src={startMobile} alt="" style={{ width: "332px", height: "auto" }} />
        <div className="overlayInitialAnimationWrapper">
          <div className="overlayInitialControlsMobile" style={{ backgroundImage: `url(${controlsMobileSprite})` }} />
        </div>
      </div>
    )
    if (!isMobile) return (
      <div
        className="overlayInitialWrapper"
        onClick={callback}
      >
        <img
          src={startDesktop}
          alt=""
          style={{
            width: "332px",
            height: "auto",
            cursor: `url(${cursorHoverImage}) 6 2, auto`
          }}
        />
        <div className="overlayInitialAnimationWrapper">
          <div className="overlayInitialControlsDesktop" style={{ backgroundImage: `url(${controlsDesktopSprite})` }} />
        </div>
      </div>
    )
  }

  const loseOverlay = () => (
    <div className="overlayLoseWrapper">
      <div
        className="overlayLoseText"
        onClick={callback}
        style={{
          backgroundImage: `url(${retrySprite})`,
          cursor: `url(${cursorHoverImage}) 6 2, auto`
        }}
      >
      </div>
      <div className="overlayLoseCtaWrapper">
        <a
          href={imageHref}
          download="what-a-christmas.png"
          className="overlayLoseCta"
        >
          <div
            className="overlayLoseCta"
            style={{
              backgroundImage: `url(${shareCtaSprite})`,
              cursor: `url(${cursorHoverImage}) 6 2, auto`
            }}
          />
        </a>
      </div>
    </div>
  )

  const CustomLink = ({ to, text }) => (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      style={{ cursor: `url(${cursorHoverImage}) 6 2, auto`}}
    >
      {text}
    </a>
  )

  const infoOverlay = () => (
    <div className="overlayInfoWrapper">
      <div className="textBlock">
        <span className="textCaption">
          DESIGNED BY
        </span>
        <span>
          <CustomLink
            to="https://www.bakoom-studio.com/"
            text="BAKOOM STUDIO"
          />
        </span>
        <span>
          <CustomLink
            to="https://www.instagram.com/unavirgulilla/"
            text="@unavirgulilla"
          />
        </span>
      </div>
      <div className="textBlock">
        <span className="textCaption">
          DEVELOPED BY
        </span>
        <span>
          <CustomLink
            to="https://www.instagram.com/carloitaben/"
            text="@carloitaben"
          />
        </span>
        <span>
          <CustomLink
            to="https://www.instagram.com/unavirgulilla/"
            text="@unavirgulilla"
          />
        </span>
      </div>
      <div className="textBlock">
        <span className="textCaption">
          MUSIC BY
        </span>
        <span>
          <CustomLink
            to="http://nullsleep.com/"
            text="Nullsleep"
          />
        </span>
      </div>
      <div className="textBlock">
        <span className="textCaption">
          SFX FROM
        </span>
        <span>
          <CustomLink
            to="https://mixkit.co/"
            text="mixkit.co"
          />
        </span>
      </div>
    </div>
  )

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        duration: 0.1,
        type: "tween",
        ease: "linear",
      }}
      className={`overlayWrapper ${hasScrim ? "scrim" : ""} ${hasBlackScrim ? "blackScrim" : ""}`}
    >
      {type === "gameStateInitial" && initialOverlay()}
      {type === "gameStateLose" && loseOverlay()}
      {type === "info" && infoOverlay()}
    </motion.div>
  )
}

export default Overlay

Overlay.propTypes = {
  type: PropTypes.oneOf(["gameStateInitial", "gameStateLose", "info"]).isRequired,
  isMobile: PropTypes.bool,
  hasScrim: PropTypes.bool,
  hasBlackScrim: PropTypes.bool,
  callback: PropTypes.func,
  imageHref: PropTypes.any,
}

Overlay.defaultProps = {
  hasScrim: false,
  hasBlackScrim: false,
}