import React, { useState } from "react"
import PropTypes from "prop-types"
import "../styles/Splash.css"
import bakoom from "../static/images/ui/bakoom.png"
import cursorImage from "../static/images/ui/cursor.png"

const Splash = ({ callback }) => {
  const [animate, setAnimate] = useState(false)
  return (
    <div
      className={`splash ${animate ? "animateSplash" : ""}`}
      style={{
        backgroundColor: "#000000",
        cursor: `url(${cursorImage}) 6 2, auto`
      }}
    >
      <img
        className="bkmLogo"
        src={bakoom}
        alt="Bakoom Studio"
        onAnimationEnd={() => {
          setAnimate(true)
          callback()
        }}
        style={{
            width: "100%",
            maxWidth: "368px", 
            height: "auto",
        }}
      />
    </div>
  )
}

export default Splash

Splash.propTypes = {
  callback: PropTypes.func.isRequired,
}