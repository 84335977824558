import { useState, useEffect, useCallback } from "react"

function useIsMobile() {
  const [width, setWidth] = useState(null)
  const [height, setHeight] = useState(null)
  const [isMobile, setIsMobile] = useState(null)

  const handleResize = useCallback((event) => {
    let width
    let height

    if (event) {
      width = event.target.innerWidth
      height = event.target.innerHeight
    } else {
      width = window.innerWidth
      height = window.innerHeight
    }

    if (width < 640) {
      setIsMobile(true)
    } else setIsMobile(false)

    setWidth(width)
    setHeight(height)
  }, [])

  useEffect(() => {
    // run on init when window is not resized
    handleResize()
    window.addEventListener("resize", handleResize, true)
    return () => window.removeEventListener("resize", handleResize)
  }, [handleResize])

  return {
    isMobile,
    screenWidth: width,
    screenHeight: height,
  }
}

export default useIsMobile
