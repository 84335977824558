import React, { useRef, useEffect, useState, useContext } from "react"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import "../styles/Collectables.css"
import collectablesSprite from "../static/images/collectables/collectablesSprite.png"
import collectableGoldenSprite1 from "../static/images/collectables/collectableGoldenSprite1.png"
import collectableGoldenSprite2 from "../static/images/collectables/collectableGoldenSprite2.png"
import collectableGoldenSprite3 from "../static/images/collectables/collectableGoldenSprite3.png"
import getRandomInt from "../utilities/getRandomInt"

import AppContext from "../utilities/AppContext"

const Collectable = ({
  keyName,
  delay,
  dengueProbability,
  collectableDuration,
  boundingBoxCallback,
  dengueMode,
  triggerNext,
  currentlyTriggered,
  hasCollided,
  resetCallback,
  last,
}) => {
  const {
    xOffset,
    screenWidth,
    screenHeight,
  } = useContext(AppContext)

  const triggered = currentlyTriggered.includes(keyName)
  const collectableEl = useRef(null)
  const [collectable, setCollectable] = useState(null)
  const [isGolden, setIsGolden] = useState(false)
  const [isActive, SetIsActive] = useState(false)
  const [triggerNextCollectable, setTriggerNextCollectable] = useState(false)
  const [xPosition, setXPosition] = useState(null)

  const animationTransition = {
    duration: collectableDuration / 100,
    delay: delay / 100,
    type: "tween",
    ease: "linear"
  }

  function onAnimationUpdate(event) {
    try {
      const { y } = event
      const arr = y.split("vh")
      const number = parseFloat(arr[0])
      const yPos = number / 100 * screenHeight
      if (yPos > screenHeight / 3 && !triggerNextCollectable) {
        setTriggerNextCollectable(true)
        if (!last) {
          const next = parseInt(keyName) + 1
          triggerNext(next.toString())
        } else {
          triggerNext("0")
        }
      }

      const boundingBox = {
        top: yPos,
        bottom: yPos + 50,
        left: xPosition,
        right: xPosition + 50
      }

      boundingBoxCallback(
        keyName,
        boundingBox,
        collectable,
        isGolden,
      )
    } catch (err) {
      console.log(err)
    }
  }
  
  // Get random collectable type w/ dengueProbability value
  function getCollectableType() {
    if (!dengueMode) {
      const value = getRandomInt(0, 3 + dengueProbability)
      if (value <= 3) {
        setCollectable(value)
        const theGoldenProbability = getRandomInt(1, 50)
        const shouldBeGolden = theGoldenProbability === 1
        if (shouldBeGolden) setIsGolden(true)
      }
    } else {
      setCollectable(0)
    }
  }

  function getRandomXPosition() {
    const securityLeft = 44 + xOffset
    let securityRight
    if (screenWidth > 1200) {
      securityRight = 1200 + xOffset - 94
    } else {
      securityRight = screenWidth - 94
    }
    let value = getRandomInt(securityLeft, securityRight)
    setXPosition(value)
    SetIsActive(true)
  }

  // Ensure collectable type & randomXPosition on screen width change
  useEffect(() => {
    if (xPosition === null && collectable === null) {
      try {
        getCollectableType()
      } finally {
        getRandomXPosition()
      }
    }
  }, [screenWidth])

  // Ensure new collectable type, then getRandomXPosition & play object animation when animation cycle ends
  useEffect(() => {
    if (!isActive) {
      try {
        getCollectableType()
      } finally {
        getRandomXPosition()
      }
    }
  }, [isActive])

  function handleCollectableSprite() {
    if (isGolden) {
      // eslint-disable-next-line default-case
      switch (collectable) {
        case 1: return collectableGoldenSprite1
        case 2: return collectableGoldenSprite2
        case 3: return collectableGoldenSprite3
      }
    }
    return collectablesSprite
  }

  if (isActive && triggered) return (
    <motion.div
      onUpdate={(e) => onAnimationUpdate(e)}
      transition={{...animationTransition}}
      initial={{x: xPosition, y: 0 }}
      animate={{x: xPosition, y: "100vh" }}
      exit={{x: xPosition, y: 0 }}
      onAnimationComplete={() => {
        resetCallback(keyName)
        SetIsActive(false)
        setTriggerNextCollectable(false)
        triggerNext(keyName, true)
      }}
      ref={collectableEl}
      className={`
        collectable
        ${isGolden ? `collectableGolden${collectable}` : `collectable${collectable}`}
        ${hasCollided ? "picked" : ""}
      `}
      style={{ backgroundImage: `url(${handleCollectableSprite()})` }}
    />
  )

  return null
}

export default Collectable

Collectable.propTypes = {
  keyName: PropTypes.string,
  delay: PropTypes.number,
  killPlayer: PropTypes.func,
  incrementPoints: PropTypes.func,
  dengueMode: PropTypes.bool,
  triggerNext: PropTypes.func,
  currentlyTriggered: PropTypes.arrayOf(PropTypes.string).isRequired,
  hasCollided: PropTypes.bool.isRequired,
  resetCallback: PropTypes.func.isRequired,
  last: PropTypes.bool,
}

Collectable.defaultProps = {
  delay: 0,
  last: false,
}