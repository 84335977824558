import React, { useState, useEffect, useRef } from "react"
import { AnimatePresence } from "framer-motion"
import { Howl } from "howler"
import "../styles/App.css"
import Splash from "./Splash"
import Container from "./Container"
import Overlay from "./Overlay"
import Background from "./Background"
import Floor from "./Floor"
import Borders from "./Borders"
import Player from "./Player"
import Collectables from "./Collectables"

import AppContext from "../utilities/AppContext"
import useIsMobile from "../utilities/useIsMobile"

import useSound from "use-sound"
import fxPickup from "../static/fx/fxPickup.mp3"
import fxPickupGolden from "../static/fx/fxPickupGolden.mp3"
import fxLose from "../static/fx/fxLose.mp3"
import fxClick from "../static/fx/fxClick.mp3"
import fxInfo from "../static/fx/fxInfo.mp3"
import fxMute from "../static/fx/fxMute.mp3"
import fxUnmute from "../static/fx/fxUnmute.mp3"
import song from "../static/fx/song.mp3"
import cursorImage from "../static/images/ui/cursor.png"
import canvasBgDesktop from "../static/images/ui/canvasBgDesktop.png"
import canvasBgMobile from "../static/images/ui/canvasBgMobile.png"

const bgSound = new Howl({
  src: [song],
  html5: true,
  autoplay: false,
  loop: true,
  volume: 0.75,
})

const App = () => {
  const {isMobile, screenWidth, screenHeight} = useIsMobile()
  const [loaded, setloaded] = useState(false)
  const [xOffset, setXOffset] = useState()
  const [touchPosition, setTouchPosition] = useState()
  const [imageHref, setImageHref] = useState()
  const overlayEl = useRef(null)
  const canvasEl = useRef(null)

  // Draw canvas background
  useEffect(() => {
    if (loaded && canvasEl.current !== null) {
      const ctx = canvasEl.current.getContext("2d")
      const img = new Image()
      if (isMobile) {
        img.src = canvasBgMobile
      } else {
        img.src = canvasBgDesktop
      }
      img.onload = () => ctx.drawImage(img, 0, 0)        
    }
  }, [canvasEl, isMobile, loaded])

  function addPointsToCanvas() {
    const ctx = canvasEl.current.getContext("2d")
    ctx.textBaseline = "middle"
    ctx.textAlign = "left"
    ctx.font = "154px visitor1"
    ctx.fillStyle = "#EDBBBB"

    const handlePoints = () => {
      if (points < 10) return `000${points}`
      if (points >= 10 && points < 100) return `00${points}`
      if (points >= 100 && points < 1000) return `0${points}`
      if (points >= 1000) return points
    }

    // Remove old text
    if (isMobile) {
      ctx.clearRect(90, 720, 402, 126)
      ctx.rect(90, 720, 402, 126)
      ctx.fill()
    } else {
      ctx.clearRect(50, 390, 490, 152)
      ctx.rect(50, 390, 490, 152)
      ctx.fill()
    }

    // Add new text
    if (isMobile) {
      ctx.fillStyle = "#A1192E"
      ctx.fillText(`${handlePoints()}`, 106, 779)
      ctx.fillStyle = "#3C683D"
      ctx.fillText(`${handlePoints()}`, 106, 772)
    } else {
      ctx.fillStyle = "#A1192E"
      ctx.fillText(`${handlePoints()}`, 106, 462)
      ctx.fillStyle = "#3C683D"
      ctx.fillText(`${handlePoints()}`, 106, 455)
    }

    // Save canvas as image
    const image = canvasEl.current.toDataURL("image/png")
    setImageHref(image)
  }

  // Overlay
  useEffect(() => {
    const el = overlayEl.current

    function handleTouchPosition(event) {
      event.preventDefault()
      if (isMobile) {
        const { clientX } = event.touches[0]
        setTouchPosition(clientX)
      }
    }
    
    if (overlayEl && el) {
      overlayEl.current.addEventListener("touchmove", handleTouchPosition)
    }
    return () => el.removeEventListener("touchmove", handleTouchPosition)
  }, [overlayEl, isMobile])

  useEffect(() => {
    if (screenWidth !== null) setloaded(true)
    if (screenWidth > 1200) {
      setXOffset((screenWidth - 1200) / 2)
    } else {
      setXOffset(0)
    }
  }, [screenWidth])

  // Fx
  const [playFxPickup] = useSound(fxPickup)
  const [playFxPickupGolden] = useSound(fxPickupGolden)
  const [playFxLose] = useSound(fxLose)
  const [playFxInfo] = useSound(fxInfo)
  const [playFxMute] = useSound(fxMute)
  const [playFxUnmute] = useSound(fxUnmute)
  const [playFxClick] = useSound(fxClick)

  // Game state logic
  const [mouseOnScreen, setMouseOnScreen] = useState(false)
  const [readyToClick, setReadyToClick] = useState(false)
  const [gameStateInitial, setGameStateInitial] = useState(true)
  const [gameStateRunning, setGameStateRunning] = useState(false)
  const [dengueMode, setDengueMode] = useState(false)
  const [gameStateLose, setGameStateLose] = useState(false)
  const [points, setPoints] = useState(0)
  const [playBgSound, setPlayBgSound] = useState(false)

  useEffect(() => {
    if (gameStateRunning && !playBgSound) {
      bgSound.play()
      setPlayBgSound(true)
    } else {
      bgSound.stop()
      setPlayBgSound(false)
    }
  }, [gameStateInitial, gameStateRunning])
  
  function setReadyToClickFunction() {
    setReadyToClick(true)
  }

  function startGame() {
    if (readyToClick) {
      playFxClick()
      setGameStateInitial(false)
      setGameStateRunning(true)
    }
  }

  function killPlayer() {
    setGameStateRunning(false)
    setGameStateLose(true)
    playFxLose()
    addPointsToCanvas()
  }

  function resetGame() {
    setGameStateLose(false)
    setGameStateRunning(true)
    setDengueMode(false)
    setPoints(0)
  }

  const goldenPickupEvent = new CustomEvent("goldenPickupEvent")
  function incrementPoints(value = 1, isGolden = false) {
    const p = points + value
    setPoints(p)
    if (isGolden) {
      playFxPickupGolden()
      window.dispatchEvent(goldenPickupEvent)
    } else {
      playFxPickup()
    }
  }

  function setDengueModeCallback(value) {
    setDengueMode(value)
  }


  // Top buttons
  const [showInfo, setShowInfo] = useState(false)
  const [isMuted, setIsMuted] = useState(false)
  
  function infoCallback() {
    setShowInfo(!showInfo)
    playFxInfo()
  }

  function muteCallback() {
    setIsMuted(!isMuted)
    if (isMuted) {
      playFxUnmute()
    } else {
      playFxMute()
    }
  }

  useEffect(() => {
    bgSound.mute(isMuted)
  }, [isMuted])

  return (
    <AppContext.Provider 
      value={{
        killPlayer,
        resetGame,
        incrementPoints,
        points,
        xOffset,
        screenWidth,
        screenHeight,
      }}
    >
      {loaded && (
        <div className="canvas-wrapper">
          {isMobile ? (
            <canvas ref={canvasEl} className="canvas" width={1080} height={1920} />
          ) : (
            <canvas ref={canvasEl} className="canvas" width={1080} height={1080} />
          )}
        </div>
      )}
      <div
        className="app"
        style={{
          backgroundColor: "#EEBBBA",
          cursor: `url(${cursorImage}) 6 2, auto`,
          height: `${screenHeight}px`
        }}
      >
        <Container dengueMode={dengueMode}>
          <Background
            type="background"
            dengueMode={dengueMode}
            gameStateLose={gameStateLose}
          />
          {loaded && (
            <Collectables
              isMobile={isMobile}
              isActive={gameStateRunning}
              screenWidth={screenWidth}
              screenHeight={screenHeight}
              dengueMode={dengueMode}
              setDengueMode={setDengueModeCallback}
            />
          )}
          <Background
            type="foreground"
            dengueMode={dengueMode}
            points={points}
            gameStateLose={gameStateLose}
            showInfo={showInfo}
          />
          <Floor dengueMode={dengueMode} />
          {loaded && (
            <Player
              isMobile={isMobile}
              isActive={gameStateRunning}
              mouseOnScreen={mouseOnScreen}
              touchPosition={touchPosition}
              xOffset={xOffset}
              screenWidth={screenWidth}
              screenHeight={screenHeight}
            />
          )}
          <div 
            ref={overlayEl}
            className="overlay"
            onMouseEnter={() => setMouseOnScreen(true)}
            onMouseLeave={() => setMouseOnScreen(false)}
          >
            <AnimatePresence initial={false}>
              {gameStateInitial && !showInfo && (
                <React.Fragment key="gameStateInitial">
                  <Overlay
                    type="gameStateInitial"
                    isMobile={isMobile}
                    callback={startGame}
                    hasScrim
                  />
                </React.Fragment>
              )}
              {gameStateLose && !showInfo && (
                <React.Fragment key="gameStateLose">
                  <Overlay
                    type="gameStateLose"
                    isMobile={isMobile}
                    callback={resetGame}
                    imageHref={imageHref}
                    hasScrim
                  />
                </React.Fragment>
              )}
              {showInfo && (
                <React.Fragment key="info">
                  <Overlay
                    type="info"
                    isMobile={isMobile}
                    hasScrim
                    hasBlackScrim
                  />
                </React.Fragment>
              )}
            </AnimatePresence>
          </div>
          <Splash callback={setReadyToClickFunction} />
          <Borders
            infoCallback={infoCallback}
            muteCallback={muteCallback}
            isMuted={isMuted}
          />
        </Container>
      </div>
    </AppContext.Provider>
  )
}

export default App;
