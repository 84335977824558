async function checkOverlap(boundingBox1, boundingBox2) {
  let collidingX = false
  let collidingY = false

  if (boundingBox2.bottom >= boundingBox1.top && boundingBox2.top <= boundingBox1.bottom) {
    collidingY = true
  }

  if (boundingBox2.right >= boundingBox1.left && boundingBox2.left <= boundingBox1.right) {
    collidingX = true
  }

  if (collidingX && collidingY) return true
  return false
}

export default checkOverlap