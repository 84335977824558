import React from "react"
import PropTypes from "prop-types"
import "../styles/Borders.css"

import cornerTopLeft from "../static/images/border/cornerTopLeft.png"
import cornerTopRight from "../static/images/border/cornerTopRight.png"
import cornerBottomLeft from "../static/images/border/cornerBottomLeft.png"
import cornerBottomRight from "../static/images/border/cornerBottomRight.png"

import borderTop from "../static/images/border/borderTop.png"
import borderRight from "../static/images/border/borderRight.png"
import borderBottom from "../static/images/border/borderBottom.png"
import borderLeft from "../static/images/border/borderLeft.png"

import logo from "../static/images/border/logo.png"

import cursorHoverImage from "../static/images/ui/cursorHover.png"
import iconInfo from "../static/images/ui/iconInfo.png"
import iconVolumeOn from "../static/images/ui/iconVolumeOn.png"
import iconVolumeOff from "../static/images/ui/iconVolumeOff.png"

const Borders = ({
  infoCallback,
  muteCallback,
  isMuted
}) => {
  return (
    <>
    <div className="logoWrapper">
      <button
        className="bordersCta marginLeft"
        type="button"
        onClick={() => muteCallback()}
        style={{ cursor: `url(${cursorHoverImage}) 6 2, auto` }}
      >
        {isMuted ? (
          <img src={iconVolumeOff} alt="" style={{ width: "32px", height: "32px" }} />
        ) : (
          <img src={iconVolumeOn} alt="" style={{ width: "32px", height: "32px" }} />
        )}
      </button>
      <img
        className="appLogo"
        src={logo}
        alt=""
        onClick={() => window.location.reload()}
        style={{
          width: "184px", height: "54px",
          cursor: `url(${cursorHoverImage}) 6 2, auto`
        }}
      />
      <button
        className="bordersCta marginRight"
        type="button"
        onClick={() => infoCallback()}
        style={{ cursor: `url(${cursorHoverImage}) 6 2, auto` }}
      >
        <img src={iconInfo} alt="" style={{ width: "32px", height: "32px" }} />
      </button>
    </div>

    <div className="border cornerTopLeft">
      <img src={cornerTopLeft} alt="" style={{ width: "30px", height: "124px" }} />
    </div>
    <div className="border borderTop">
      <img className="borderTopImage" src={borderTop} alt="" style={{ width: "100%", height: "116px" }} />
    </div>
    <div className="border cornerTopRight">
      <img src={cornerTopRight} alt="" style={{ width: "30px", height: "124px" }} />
    </div>

    <div className="border borderLeft">
      <img className="borderLeftImage" src={borderLeft} alt="" style={{ width: "22px", height: "100%" }} />
    </div>
    <div className="border borderRight">
      <img className="borderRightImage" src={borderRight} alt="" style={{ width: "22px", height: "100%" }} />
    </div>

    <div className="border cornerBottomLeft">
      <img src={cornerBottomLeft} alt="" style={{ width: "30px", height: "42px" }} />
    </div>
    <div className="border borderBottom">
      <img className="borderBottomImage" src={borderBottom} alt="" style={{ width: "100%", height: "34px" }} />
    </div>
    <div className="border cornerBottomRight">
      <img src={cornerBottomRight} alt="" style={{ width: "30px", height: "42px" }} />
    </div>
  </>
  )
}

export default Borders

Borders.propTypes = {
  infoCallback: PropTypes.func.isRequired,
  muteCallback: PropTypes.func.isRequired,
  isMuted: PropTypes.bool.isRequired,
}