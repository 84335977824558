import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import "../styles/Background.css"

import Mountains from "../static/images/scene/mountains.png"
import MountainsDengue from "../static/images/scene/mountainsDengue.png"
import Stars from "../static/images/scene/stars.png"
import Clouds from "../static/images/scene/clouds.png"
import CloudsDengue from "../static/images/scene/cloudsDengue.png"

const Background = ({ type, points, dengueMode, gameStateLose, showInfo }) => {
  const [animation, setAnimation] = useState(false)
  const [golden, setGolden] = useState(false)

  useEffect(() => {
    setAnimation(true)
  }, [points])
  
  const handlePoints = () => {
    if (points < 10) return `000${points}`
    if (points >= 10 && points < 100) return `00${points}`
    if (points >= 100 && points < 1000) return `0${points}`
    if (points >= 1000) return points
  }

  function handleGoldenPickupEvent() {
    setGolden(true)
  }

  useEffect(() => {
    window.addEventListener("goldenPickupEvent", handleGoldenPickupEvent)
    return () => window.removeEventListener("goldenPickupEvent", handleGoldenPickupEvent)
  }, [])
  
  return (
    <>
      {type === "foreground" && (
        <>
          <div className="clouds-wrapper">
            <div
              className={`clouds background-dengue ${dengueMode ? "background-dengue-appear" : ""}`}
              style={{
                backgroundImage: `url(${CloudsDengue})`,
                backgroundRepeat: "repeat-x",
                backgroundSize: "720px 100%",
              }}
            />
            <div
              className="clouds"
              style={{
                backgroundImage: `url(${Clouds})`,
                backgroundRepeat: "repeat-x",
                backgroundSize: "720px 100%",
              }}
            />
          </div>
          <div
            className={`
              pointsWrapper
              ${gameStateLose && !showInfo ? "pointsWrapperOnTop" : ""}
              ${golden ? "pointsWrapperGolden" : ""}
            `}
            onAnimationEnd={() => setGolden(false)}
          >
            <span
              className={`${animation ? "pointsAnimation" : ""}`}
              onAnimationEnd={() => setAnimation(false)}
            >
              {handlePoints()}
            </span>
          </div>
        </>
      )}
      {type === "background" && (
        <>
          <div
            className="stars"
            style={{
              backgroundImage: `url(${Stars})`,
              backgroundRepeat: "repeat",
              backgroundSize: "176px 176px",
            }}
          />
          <div className="mountains-wrapper">
            <div
              className={`mountains background-dengue ${dengueMode ? "background-dengue-appear" : ""}`}
              style={{
                backgroundImage: `url(${MountainsDengue})`,
                backgroundRepeat: "repeat",
                backgroundPosition: "center",
                backgroundSize: "560px 100%",
              }}
            />
            <div
              className="mountains"
              style={{
                backgroundImage: `url(${Mountains})`,
                backgroundRepeat: "repeat",
                backgroundPosition: "center",
                backgroundSize: "560px 100%",
              }}
            />
          </div>
        </>
      )}
    </>
  )
}

export default Background

Background.propTypes = {
  type: PropTypes.oneOf(["background", "foreground"]).isRequired,
  points: PropTypes.number,
  dengueMode: PropTypes.bool.isRequired,
  gameStateLose: PropTypes.bool.isRequired,
  showInfo: PropTypes.bool,
}

Background.defaultProps = {
  showInfo: false,
}