import React from "react"
import PropTypes from "prop-types"
import "../styles/Container.css"

const Container = ({ children, dengueMode }) => (
  <div className="container">
    <div className="backgroundOverlayWrapper">
      <div className="backgroundOverlayDengue" />
      <div className={`backgroundOverlay ${dengueMode ? "backgroundOverlayHide" : ""}`} />
    </div>
    {children}
  </div>
)

export default Container

Container.propTypes = {
  children: PropTypes.node.isRequired,
  denguemode: PropTypes.bool,
}
