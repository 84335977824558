import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import "../styles/Player.css"

import getRandomInt from "../utilities/getRandomInt"
import clamp from "../utilities/clamp"
import playerDesktopSprite from "../static/images/player/playerDesktopSprite.png"
import playerMobileSprite1 from "../static/images/player/playerMobileSprite1.png"
import playerMobileSprite2 from "../static/images/player/playerMobileSprite2.png"
import playerMobileSprite3 from "../static/images/player/playerMobileSprite3.png"

import useSound from "use-sound"
import fxWalk from "../static/fx/fxWalk.mp3"

const Player = ({
  isActive,
  isMobile,
  mouseOnScreen,
  touchPosition,
  xOffset,
  screenWidth,
  screenHeight,
}) => {
  const [character, setCharacter] = useState(1)
  const [playerPosition, setPlayerPosition] = useState()
  const [playerWidth, setPlayerWidth] = useState()
  const [playerHeight, setPlayerHeight] = useState()
  const [throttle, setThrottle] = useState(false)
  const [playFxWalk] = useSound(fxWalk)

  useEffect(() => {
    const value = getRandomInt(1, 3)
    switch(value) {
      default:
      case 1: return setCharacter(playerMobileSprite1)
      case 2: return setCharacter(playerMobileSprite2)
      case 3: return setCharacter(playerMobileSprite3)
    }
  }, [])

  // Center player on mount & send initial boundingBoxValue
  useEffect(() => {
    if (isMobile) {
      setPlayerWidth(60)
      setPlayerHeight(72)
      setPlayerPosition(screenWidth / 2 - 60)
      onAnimationUpdate(screenWidth / 2 - 60)
    } else {
      setPlayerWidth(132)
      setPlayerHeight(98)
      if (screenWidth > 1200) {
        setPlayerPosition(screenWidth / 2 - xOffset - 88)
        onAnimationUpdate(screenWidth / 2 - xOffset - 88)
      } else {
        setPlayerPosition(screenWidth / 2 - 88)
        onAnimationUpdate(screenWidth / 2 - 88)
      }
    }
  }, [isMobile])

  // Throttle mouse events
  useEffect(() => {
    const timeout = setTimeout(() => {
      setThrottle(false)
    }, 25)

    return () => clearTimeout(timeout)
  }, [throttle, playerPosition])

  // Handle mouse position
  function handleMousePosition(event) {
    if (mouseOnScreen && !isMobile && isActive) {
      if (!throttle) {
        const { clientX } = event
        // 88 es margen de seguridad
        const clientXAdjustedValue = clientX - xOffset - 88
        let maxValue
        if (screenWidth > 1200) {
          // 198 es el ancho del moñeco + margen de seguridad
          maxValue = 1200 - 198
        } else {
          maxValue = screenWidth - 198
        }
        const clampedValue = clamp(clientXAdjustedValue, 22, maxValue)
        setPlayerPosition(clampedValue)
        setThrottle(true)
      }
    }
  }

  // Mobile position
  useEffect(() => {
    if (isMobile && isActive) {
      const clampedValue = clamp(touchPosition - 46, 22, screenWidth - 128)
      setPlayerPosition(clampedValue)
    }
  }, [touchPosition])

  // Handle listeners
  useEffect(() => {    
    window.addEventListener("mousemove", handleMousePosition)
    return () => window.removeEventListener("mousemove", handleMousePosition)
  })

  const [fxThrottle, setFxThrottle] = useState(false)

  useEffect(() => {
    let fxTimer
    if (fxThrottle) {
      fxTimer = setTimeout(() => {
        setFxThrottle(false)
        clearTimeout(fxTimer)
      }, 300)
    }

    return () => {
      if (typeof fxTimer !== undefined) {
        clearTimeout(fxTimer)
      }
    }
  }, [fxThrottle])

  // handle position update
  const checkVelocityRef = useRef([playerPosition])

  function onAnimationUpdate(event) {
    if (!fxThrottle && isActive) {
      const value = Math.abs(checkVelocityRef.current[0] - checkVelocityRef.current[1])
      if (value > 2) {
        playFxWalk()
      }
      setFxThrottle(true)
    }

    const { x } = event
    const handleX = typeof x === "undefined" ? playerPosition : x
    const boundingBox = {
      top: screenHeight - 124 - playerHeight,
      bottom: screenHeight - 124 - playerHeight / 2,
      left: handleX + xOffset,
      right: handleX + xOffset + playerWidth,
    }

    const playerPositionEvent = new CustomEvent(
      "playerPositionEvent", {
        detail: { boundingBox }
      }
    )

    window.dispatchEvent(playerPositionEvent)

    if (checkVelocityRef.current.length < 2) {
      checkVelocityRef.current.push(x)
    } else {
      checkVelocityRef.current.splice(0, 1)
      checkVelocityRef.current.push(x)
    }
  }

  return (
    <motion.div
      onUpdate={(e) => onAnimationUpdate(e)}
      animate={{ x: playerPosition }}
      transition={{ duration: 0.2, type: "tween", ease: "easeOut" }}
      className={`${isMobile ? "playerMobile playerMobileIdle" : "playerDesktop playerDesktopIdle"}`}
      style={{ backgroundImage: `url(${isMobile ? character : playerDesktopSprite})` }}
    />
  )
}

export default Player

Player.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool,
  mouseOnScreen: PropTypes.bool.isRequired,
  touchPosition: PropTypes.number,
  xOffset: PropTypes.number,
  screenWidth: PropTypes.number,
  screenHeight: PropTypes.number,
}
