import React from "react"
import PropTypes from "prop-types"
import "../styles/Floor.css"

import FloorImage from "../static/images/scene/floor.png"
import FloorImageDengue from "../static/images/scene/floorDengue.png"

const Floor = ({ dengueMode }) => (
  <div className="floor-wrapper">
    <div
      className={`floor floor-dengue ${dengueMode ? "floor-dengue-appear" : ""}`}
      style={{
        backgroundImage: `url(${FloorImageDengue})`,
        backgroundRepeat: "repeat-x",
        backgroundSize: "520px 100%",
      }}
    />
    <div
      className="floor"
      style={{
        backgroundImage: `url(${FloorImage})`,
        backgroundRepeat: "repeat-x",
        backgroundSize: "520px 100%",
      }}
    />
  </div>
)

export default Floor

Floor.propTypes = {
  dengueMode: PropTypes.bool.isRequired
}
